<template>
  <section>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                Phrases
                <span class="d-block text-muted pt-2 font-size-sm"
                  >Phrases created by Customers</span
                >
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Button-->

              <v-flex>
                <add_dialog />
              </v-flex>
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    :headers="headers"
                    :items="phrases"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    :custom-sort="customSort"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:item.score="{ item }"
                      >{{ (item.results_count * item.queries) / 2 }}
                    </template>
                    <template v-slot:item.active="{ item }">
                      <v-col cols="6">
                        <v-switch
                          @change="visibility(item.id, item.active)"
                          v-model="item.active"
                          :label="item.active ? 'visible' : 'hidden'"
                        ></v-switch>
                      </v-col>
                    </template>
                    <template v-slot:item.boost="{ item }">
                      <v-col cols="6">
                        <v-switch
                          @change="boosted(item.id, item.boost)"
                          v-model="item.boost"
                          :label="item.boost ? 'boosted' : ''"
                        ></v-switch>
                      </v-col>
                    </template>

                    <template v-slot:top>
                      <div
                        class="d-flex align-items-center position-relative my-1"
                      >
                        <span
                          class="svg-icon svg-icon-1 position-absolute ms-6"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19
                              11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333
                              17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          placeholder="Search in phrases"
                          label="Search"
                          class="form-control form-control-solid w-250px ps-14"
                        />
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import add_dialog from "./add_dialog";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  PULL_PHRASES,
  PUT_PHRASE_BOOST,
  PUT_PHRASE_VISIBLE
} from "@/core/services/store/phrases.module";

export default {
  components: { add_dialog },
  data() {
    return {
      search: "",
      options: {},
      headers: [
        { text: "Term", value: "term" },
        { text: "Result count", value: "results_count" },
        { text: "Queries count", value: "queries" },
        { text: "Score", value: "score" },
        { text: "Last Update Time", value: "update_time" },
        { text: "Visible", value: "active" },
        { text: "Boost", value: "boost" }
      ]
    };
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index.toString() === "score") {
          if (isDesc.toString() === "false") {
            return (a.results_count * a.queries) / 2 <
              (b.results_count * b.queries) / 2
              ? -1
              : 1;
          } else {
            return (b.results_count * b.queries) / 2 <
              (a.results_count * a.queries) / 2
              ? -1
              : 1;
          }
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    reloadData() {
      this.$store.dispatch(PULL_PHRASES);
    },
    visibility(id, visibility) {
      this.$store
        .dispatch(PUT_PHRASE_VISIBLE, {
          id,
          visibility
        })
        .then(() => {});
    },
    boosted(id, boost) {
      this.$store
        .dispatch(PUT_PHRASE_BOOST, {
          id,
          boost: boost
        })
        .then(() => {});
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.phrases.errors,
      message: state => state.phrases.message,
      hasMessage: state => state.phrases.hasMessage,
      phrases: state => state.phrases.data,
      loading: state => state.phrases.loading
    }),
    ...mapGetters(["currentProject"])
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Phrases" }]);
  }
};
</script>
